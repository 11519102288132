import { memo, useCallback } from "react"

import { useAppContext } from "@spatialsys/web/app-context"
import { Button, cn } from "@spatialsys/web/ui"

import { useLocale } from "../../homepage"
import { SectionHeader } from "../../layouts/marketing/section/section-header"
import { ExploreMarquee } from "./explore-marquee"

type ExploreProps = {
  isSmallScreen: boolean
}

export const Explore = memo(function Explore({ isSmallScreen }: ExploreProps) {
  const locale = useLocale()
  const isKorean = locale === "ko" || locale === "ko-KR"
  const actions = useAppContext((context) => context.actions)
  const showOldHomepage = useCallback(() => {
    actions.showOldHomepage()
  }, [actions])

  return (
    <div className={cn("z-10 bg-background", isSmallScreen ? "pb-[72px] pt-[48px]" : "pb-[120px] pt-[72px]")}>
      <SectionHeader
        title={isKorean ? "완전히 새로운 경험" : "Explore"}
        titleClassName={isKorean ? "font-kr font-bold" : undefined}
        catchphrase={isKorean ? "지금, 무한한 세상으로 모험을 떠나보세요" : "Explore Limitless Experiences Today"}
        catchphraseClassName={isKorean ? "font-kr" : undefined}
        description={
          isKorean ? (
            <>
              수백만 개의 실감나는 세상들이 당신을 기다리고 있습니다.
              <br />
              스페이셜 커뮤니티와 관심있는 브랜드들의 이야기들을 탐험해보세요.
            </>
          ) : (
            "Dive into millions of virtual worlds, connect with others, and explore creations from the Spatial community and the brands you love."
          )
        }
        descriptionClassName={isKorean ? "font-kr" : undefined}
      />
      <div className="mx-auto mt-14 w-full max-w-screen-4xl">
        <ExploreMarquee />
      </div>
      <div className="mt-8 grid w-full px-4 sm:mt-14">
        <Button
          size={isSmallScreen ? "lg" : "xl"}
          className={cn(
            "relative w-full justify-self-center overflow-hidden bg-gradient-to-r from-[#00CCFF] to-[#0033FF] text-sm text-white sm:max-w-[480px] sm:text-lg",
            isKorean && "font-kr font-bold"
          )}
          onClick={showOldHomepage}
          noScaleOnHover={isSmallScreen}
        >
          <span className="pointer-events-none z-10">{isKorean ? "더 알아보기" : "Explore more"}</span>
          <div className="absolute right-0 top-0 size-full bg-[#0044ff] opacity-0 transition-opacity duration-500 hover:opacity-100" />
        </Button>
      </div>
    </div>
  )
})
